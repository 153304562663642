export const singleRegios = {
    init() {
        // JavaScript to be fired on all pages
        const $tab = $('.region-single__tab');

        $tab.on('click', function (e) {
            e.preventDefault();

            const anchor = $(this).data('anchor');
            const scrollToContainer = $('#' + anchor);
            let extraHeight = 74;

            if($(window).innerWidth() < 1024) {
                extraHeight = 0;
            }

            if (scrollToContainer.length > 0) {
                console.log('Scroll!');
                $('html, body').animate({
                    scrollTop: scrollToContainer.offset().top - extraHeight,
                }, 500);
            }
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
