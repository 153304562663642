import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
gsap.registerPlugin(ScrollTrigger);

const scrollAnimations = () => {
    const duration = 0.5;
    let section = document.querySelectorAll('[data-animation-section]');
    if (section.length !== false && section) {
        section.forEach((currentSection) => {
            const delay = 0.2;
            let timeBlock = 0;
            let timeImage = 0;

            setTimeout(() => {
                currentSection.querySelectorAll('[data-animation-reveal]').forEach((elem) => {
                    const type = elem.getAttribute('data-animation-reveal-type');
                    if (type === 'translate') {
                        ScrollTrigger.create({
                            trigger: currentSection,
                            start: getTriggerStart(),
                            once: true,
                            onEnter: function () {
                                animateFrom(elem, timeBlock);
                                timeBlock = timeBlock + delay;
                            },
                        });
                    }

                    if (type === 'scale') {
                        ScrollTrigger.create({
                            trigger: currentSection,
                            start: getTriggerStart(),
                            once: true,
                            onEnter: function () {
                                animateScale(elem, timeImage);
                                timeImage = timeImage + delay;
                            },
                        });
                    }

                    if (type === 'blur') {
                        ScrollTrigger.create({
                            trigger: currentSection,
                            start: getTriggerStart(),
                            once: true,
                            onEnter: function () {
                                animateBlur(elem, timeImage);
                                timeImage = timeImage + delay;
                            },
                        });
                    }
                });
            }, getTriggerTimer());
        });
    }

    window.addEventListener('load', () => {
        const teamSection = document.querySelector('.js-team-page');
        const viewportWidth = getViewportWidth();
        const show = viewportWidth < 768;

        if (teamSection && show) {
            teamSection.querySelectorAll('.js-team-member').forEach((elem) => {
                let video = elem.querySelector('video');
                if(video) {
                    ScrollTrigger.create({
                        trigger: elem,
                        start: 'top 50%',
                        end: 'bottom',
                        once: false,
                        onEnter: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.play();
                                video.classList.remove('grayscale-[95%]')
                                video.classList.remove('opacity-95')
                            }
                        },
                        onLeave: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.pause();
                                video.classList.add('grayscale-[95%]')
                                video.classList.add('opacity-95')
                            }
                        },
                        onEnterBack: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.play();
                                video.classList.remove('grayscale-[95%]')
                                video.classList.remove('opacity-95')
                            }
                        },
                        onLeaveBack: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.pause();
                                video.classList.add('grayscale-[95%]')
                                video.classList.add('opacity-95')
                            }
                        },
                    });
                }
            });
        }
    });

    function getViewportWidth()
    {
        return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    }

    function getTriggerStart()
    {
        let triggerStart = 'top 90%';
        if (getViewportWidth() < 1024) {
            triggerStart = 'top 100%';
        }
        return triggerStart;
    }

    function getTriggerTimer()
    {
        let triggerTimer = 250;
        if (getViewportWidth() < 1024) {
            triggerTimer = 150;
        }
        return triggerTimer
    }

    function animateFrom(elem, delay)
    {
        gsap.to(elem, {
            duration: duration,
            delay: delay,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: 'power1',
            overwrite: 'auto',
        });
    }

    function animateScale(elem, delay)
    {
        gsap.to(elem, {
            duration: duration,
            delay: delay,
            scale: 1,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: 'power1',
            overwrite: 'auto',
        });
    }

    function animateBlur(elem, delay)
    {
        gsap.to(elem, {
            duration: duration,
            delay: delay,
            x: 0,
            y: 0,
            autoAlpha: 1,
            scale: 1,
            ease: 'power1',
            overwrite: 'auto',
            webkitFilter: 'blur(0px)',
            filter: 'blur(0px)',
        });
    }
}

export default scrollAnimations;
