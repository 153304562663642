export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        let ua = navigator.userAgent || navigator.vendor || window.opera;
        if((ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1) && ua.indexOf('Android') > -1) {
            $('.header__menu__btn').css('bottom', '150px');
        }

        $('.filter__dropdown__label').on('click', function () {
            $(this).closest('.filter__inner').toggleClass('open')
        })


        $('.slick-slider').css('opacity', '1');

        // Menu overlay toggle
        var clicks;
        $('.header__menu__btn').click(function () {
            clicks = $(this).data('clicks');
            if (clicks)
                $('body').removeClass('header__menu__open');
            else
                $('body').addClass('header__menu__open');
            $(this).data('clicks', !clicks);
        });

        // Submenu toggle
        if ($(window).width() < 991) {
            $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>')
            $('.menu-item-touch').on('click', function () {
                $(this).parent('.menu-item').toggleClass('open')
            });
        }

        menuLineAnimation();
        function menuLineAnimation() {
            // Active menu line animation
            var j$ = jQuery,
                $slideLine = j$('#slide-line'),
                $currentItem = j$('.header__menu > .nav > .menu-item.active');

            j$(function () {
                // Menu has active item
                if ($currentItem[0]) {
                    $slideLine.css({
                        'width': $currentItem.width(),
                        'left': $currentItem.position().left + parseInt($currentItem.css('padding-left')),
                    });
                }

                // Underline transition
                j$('.header__menu > .nav > .menu-item').hover(
                    // Hover on
                    function () {
                        $slideLine.css({
                            'width': j$(this).width(),
                            'left': j$(this).position().left + parseInt(j$(this).css('padding-left')),
                        });
                    },
                    // Hover out
                    function () {
                        if ($currentItem[0]) {
                            // Go back to current
                            $slideLine.css({
                                'width': $currentItem.width(),
                                'left': $currentItem.position().left + parseInt($currentItem.css('padding-left')),
                            });
                        } else {
                            // Disapear
                            $slideLine.width(0);
                        }
                    }
                );
            });
        }

        // Scroll to section
        $('.scroll-to-section').click(function () {
            var targetElement = $(this).attr('data-scroll-target');
            if (targetElement) {
                $('html, body').animate({
                    scrollTop: $('#' + targetElement).offset().top - 100,
                }, 'slow');
            }
        });

        // Banner social alignment fix
        bannerFix();
        function bannerFix() {
            if ($('.banner__social').length)
                $('.banner__social').attr('style', 'left:' + $('.banner__content').offset().left + 'px');
        }

        $(window).resize(function () {
            bannerFix();
        });

        setTimeout(function () {
            bannerFix();
            menuLineAnimation();
        }, 1000);

        if ($(window).width() > 991) {
            setTimeout(function () {
                $('.front__search').attr('style', 'opacity: 1;');
            }, 3900);
        } else {
            setTimeout(function () {
                $('.front__search').attr('style', 'opacity: 1;');
            }, 3200);
        }

        setTimeout(function () {
            $('.block__summary').matchHeight();
        }, 300);

        $(document).on('sf:ajaxfinish', '.searchandfilter', function(){
            $('.block__summary').matchHeight();
        });

        $(window).scroll(function () {
            if ($(window).scrollTop() > ($(window).height() / 2))
                $('.footer__icons').addClass('footer__icons--show');
            else
                $('.footer__icons').removeClass('footer__icons--show');
        });

        // Wow Animation
        // new WOW().init();

        // new CircleType(document.getElementById('location__text')).radius(7);

        $('.referenties__slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: true,
            fade: true,
            speed: 500,
            autoplay: true,
            swipeToSlide: true,
            dots: false,
            asNavFor: '.referenties__slider__nav',
            nextArrow: '<div class="referenties__slider__next"><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg></div>',
            prevArrow: '<div class="referenties__slider__prev"><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"></path></svg></div>',
        });
        $('.referenties__slider__nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            asNavFor: '.referenties__slider',
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true,
        });

        $('.oplossingen__slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: true,
            fade: true,
            speed: 500,
            autoplay: true,
            swipeToSlide: true,
            dots: false,
            nextArrow: '<div class="oplossingen__slider__next"><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg></div>',
            prevArrow: '<div class="oplossingen__slider__prev"><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"></path></svg></div>',
        });
        $('.oplossingen__slider__nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true,
        });

        $('a[data-slide]').click(function (e) {
            e.preventDefault();
            var slideno = $(this).data('slide');
            $('.slider__nav').slick('slickGoTo', slideno - 0);
        });

        $('.front__reference__slider').slick({
            dots: false,
            draggable: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 1000,
            touchThreshold: 100,
            swipeToSlide: true,
            variableWidth: true,
        });

        $('.front__solutions__slider').slick({
            slidesToShow: 4,
            dots: false,
            draggable: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            touchThreshold: 100,
            swipeToSlide: true,
            variableWidth: true,
        });

        if($('.solutions__reference .related__solution').length >= 5) {
            $('.solutions__reference .related__solution').each(function () {
                $(this).removeClass().addClass('col-12').addClass('related__solution');
            });

            $('.solutions__reference .row').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                arrows: true,
                speed: 500,
                autoplay: true,
                swipeToSlide: true,
                dots: false,
                nextArrow: '<div class="oplossingen__slider__next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 256c0 1.1-.7344 3.969-2.219 5.531l-144 151.1c-3.047 3.187-8.125 3.312-11.31 .25c-3.188-3.094-3.281-8.156-.25-11.31l138.7-146.5L66.21 109.5C63.18 106.3 63.27 101.3 66.46 98.22c3.188-3.062 8.266-2.937 11.31 .25l144 151.1C223.3 252 224 254 224 256z"/></svg></div>',
                prevArrow: '<div class="oplossingen__slider__prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M32 255.1c0-1.1 .7344-3.969 2.219-5.531l144-151.1c3.047-3.187 8.125-3.312 11.31-.25c3.188 3.094 3.281 8.156 .25 11.31L51.08 255.1l138.7 146.5c3.031 3.219 2.938 8.281-.25 11.31c-3.188 3.062-8.266 2.937-11.31-.25L34.22 261.5C32.73 259.1 32 257.1 32 255.1z"/></svg></div>',
                pauseOnHover: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },

                ],
            });

            $('.usps__holder .row').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
                speed: 500,
                autoplay: true,
                swipeToSlide: true,
                dots: false,
                pauseOnHover: false,
                mobileFirst: true,
                fade: true,
                cssEase: 'linear',
                responsive: [
                      {
                              breakpoint: 991,
                              settings: 'unslick',
                      },
                ],
            });

            $(window).resize(function () {
                $('.solutions__reference .row').not('.slick-initialized').slick('resize');
            });

            $(window).on('orientationchange', function () {
                $('.solutions__reference .row').not('.slick-initialized').slick('resize');
            });
        }

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
